<template>
    <v-col
            class="pa-0"
    >
        <v-row class="cs-row-search-popover">
            <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    max-width="460px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            :id="placeholder+'_selector'"
                            :class="{'filled': value != null}"
                            :disabled="disabled"
                            :loading="loading"
                            :title="'Selección de ' + placeholder"
                            class="cb-button-search-popover text-truncate"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                    >
                        {{ formatValue() }}
                    </v-btn>
                </template>

                <v-card>
                    <v-row
                            class="px-2 py-1"
                            justify="space-between"
                    >
                        <v-col class="my-auto pa-0">
                            <v-icon class="mr-2">{{ icon }}</v-icon>
                            <span class="my-auto">Seleccionar {{ placeholder }}</span>
                        </v-col>
                        <span
                                v-if="items"
                                class="my-auto"
                        >
            Resultados <strong>{{ items.length }}</strong>
          </span>
                    </v-row>

                    <v-divider></v-divider>
                    <v-row class="px-2 mt-1">
                        <v-col>
                            <v-text-field
                                    v-model="searchText"
                                    :placeholder="'Buscar '+placeholder"
                                    autofocus
                                    clearable
                                    full-width
                                    hide-details
                                    outlined
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-1"/>
                    <v-row style="max-height: 320px;overflow-y: auto">
                        <v-col class="pa-2">
                            <v-list
                                    v-if="items != null && items.length >0"
                                    dense
                            >
                                <v-list-item
                                        v-for="(item,index) in items"
                                        :key="index"
                                        :title="text_name != null ? item[text_name] : item"
                                        class="cb-list-finder text-truncate"
                                        dense
                                        link
                                        two-line
                                        @click="selectItem(item)"
                                >
                                    <v-list-item-avatar>
                                        <v-icon :color="getItemColor(item)">{{
                                            icon
                                            }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content v-if="text_name == null">
                                        <v-list-item-title class="font-weight-medium">{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content v-if="hide_value">
                                        <v-list-item-title class="font-weight-medium">{{
                                            item[text_name]
                                            }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content v-else-if="text_value == text_name">
                                        <v-list-item-title class="font-weight-medium">{{
                                            item[text_name]
                                            }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content v-else>
                                        <v-list-item-subtitle v-if="!hideTextValue">{{
                                            item[text_value]
                                            }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title class="font-weight-medium">{{
                                            item[text_name]
                                            }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-row
                                    v-else-if="loading"
                                    class="pa-2"
                                    justify="center"
                            ><span>Cargando</span></v-row>
                            <v-row
                                    v-else
                                    class="pa-2"
                                    justify="center"
                            >Aquí se mostrarán los resultados coincidentes
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-menu>

            <v-btn
                    id="clear"
                    :class="{'filled': value != null}"
                    :disabled="value == null || not_null || disabled"
                    color="primary"
                    icon
                    outlined
                    small
                    @click="clearValue"
            >
                <v-icon>clear</v-icon>
            </v-btn>
        </v-row>
    </v-col>
</template>

<script>
import helpers from '@/plugins/helpers'
import service_explorers from '@/services/global/service_explorers'

export default {
    name: 'FinderSelector',
    props: {
        propName: {
            type: String,
            default: null
        },
        text_name: String,
        additional_values: {
            type: Array,
            default: null
        },
        text_value: String,
        placeholder: String,
        hide_value: Boolean,
        select_first: Boolean,
        disabled: {
            type: Boolean,
            default: false
        },
        not_null: {
            type: Boolean,
            default: false
        },
        value: [String, Object, Number, Array],
        return_object: {
            type: Boolean,
            default: false
        },
        url: String,
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        icon: {
            default: 'circle',
            type: String
        },

        hideTextValue: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            helpers,
            loading: false,
            items: null,
            search: '',
            newValue: null,
            menu: false,
            searchText: null,
        }
    },
    watch: {
        searchText() {
            this.find()
        },
        menu(val) {
            if (val) {
                this.find()
            }
        },

        value(val) {
            if (helpers.nullOrEmpty(val)) {
                this.newValue = null
            }
            if (this.propName) {
                this.$emit('change', {[this.propName]: val})
            }
        }

    },
    created() {
        if (this.value != null) {
            this.find(true)
        }
    },
    methods: {
        getItemColor(item) {
            if (this.text_value == null) {
                return ""
            } else {
                return item.color != null ? item.color : helpers.stringToColor(item[this.text_value])
            }

        },
        formatValue() {
            let val = this.newValue != null ? this.newValue : this.value
            if (val == null) {
                return 'Vacío'
            }
            if (val[this.text_name] != null) {
                if (this.hide_value) {
                    return val[this.text_name]
                }

                return val[this.text_value] != val[this.text_name] ? val[this.text_value] + ' | ' + val[this.text_name] : val[this.text_value]
            }
            if (val[this.text_value] != null) {
                return val[this.text_value]
            }
            return val
        },
        clearValue() {
            this.newValue = null
            this.searchText = null
            this.$emit('input', null)
        },
        selectItem(item) {
            this.newValue = item
            if (this.return_object) {
                this.$emit('input', item)
            } else {
                this.$emit('input', this.text_value != null ? item[this.text_value] : item)
            }
            this.menu = false
        },
        find(firstFind) {
            if (helpers.nullOrEmpty(this.url)) {
                this.items = this.additional_values
                return
            }
            this.loading = true
            this.items = []
            let filters = Object.assign({}, this.filters)
            let fields = this.text_name == this.text_value ? [this.text_name] : [this.text_name, this.text_value]

            let value = firstFind ? this.value : this.searchText

            Object.keys(filters).forEach(f => {
                if (f != 'OR' && fields.find(field => field == f) == null) {
                    fields.push(f)
                }
            })

            if (firstFind) {
                filters = {
                    [this.text_value]: value
                }
            } else {

                if (value != null) {
                    filters['OR'] = {
                        [this.text_value + '[~]']: value,
                        [this.text_name + '[~]']: value
                    }
                } else {
                    this.$delete(filters, 'OR')
                }
            }
            let options = {"page": 1, "rpp": 99999, "order": {"field": fields[0], "direction": "ASC"}}
            service_explorers.find(this.url, fields, filters, options).then(extra => {

                this.items = extra.results
                if (this.additional_values != null) {
                    this.additional_values.forEach(av => {
                        this.items.unshift(av)
                    })
                }
                if (firstFind) {
                    if (extra.results != null && extra.results.length == 1) {
                        this.newValue = extra.results[0]
                    } else if (extra.results != null) {
                        this.newValue = extra.results.find(er => er[this.text_value] == value)
                    }
                }
                this.$emit('changeItems', extra.results)
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss">
@import "../../../../src/assets/styles/resources/colors";


.cs-row-search-popover {
  justify-content: space-between;


  & .cb-button-search-popover.filled {
    border: 1px solid $color_app_primary;
    border-right: 0;

  }

  & #clear.filled {
    border: solid 1px $color_app_primary !important;
    border-left: 0 !important;

  }

  & .cb-button-search-popover {
    width: calc(100% - 30px) !important;
  }

  & #clear {
    width: 30px;
    border: solid 1px $color_app_contrast_medium;
    border-left: 0 !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background: $color_app_clear;

    & #clear.disabled {
      background: $color_app_contrast_low;
    }

    & i {
      margin: 0;
    }
  }
}

.cb-button-search-popover {
  background-color: $color_app_active;
  border: solid 1px $color_app_contrast_medium;
  width: 100%;
  height: 28px !important;
  padding: 0;
  border-right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cb-list-finder {
  height: auto !important;
  max-height: inherit !important;
  min-height: auto !important;
  border-bottom: 1px solid $color_app_contrast_low;

  & .v-list-item__avatar {
    height: 32px !important;
    width: 32px !important;
    max-width: 32px !important;
    min-width: 32px !important;
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
}
</style>

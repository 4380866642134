import { MONITORING } from '@/constants/categories'
import {
  P_MONITORING_ALERTS,
  P_MONITORING_HOSTS,
  P_MONITORING_PROVIDERS,
  P_MONITORING_STATS
} from '@/constants/group_permissions'
import icons from '@/constants/icons'

const Statistics = () => import(/* webpackChunkName: "Monitoring"*/ '@/views/monitoring/Statistics')
const Providers = () => import(/* webpackChunkName: "Monitoring"*/ '@/views/monitoring/Providers')
const Hosts = () => import(/* webpackChunkName: "Monitoring"*/ '@/views/monitoring/Hosts')
const Alerts = () => import(/* webpackChunkName: "Monitoring"*/ '@/views/monitoring/Alerts')
const Logs = () => import(/* webpackChunkName: "Monitoring"*/ '@/views/monitoring/Logs')

const ROOT = '/monitoring/'
const CATEGORY = MONITORING
export default [
  {
    path: ROOT + 'alerts',
    name: 'alerts',
    component: Alerts,
    meta: {
      perm: P_MONITORING_ALERTS,
      title: 'Gestión de alertas de CertySign',
      title_short: 'Alertas',
      navigable: true,
      icon: icons.I_ALERT,
      category: CATEGORY,
      requiresAuth: true
    }
  },
  {
    path: ROOT + 'stats',
    name: 'stats',
    component: Statistics,
    meta: {
      perm: P_MONITORING_STATS,
      title: 'Estadísticas',
      title_short: 'Estadísticas',
      icon: icons.I_STATS,
      navigable: true,
      category: CATEGORY,
      requiresAuth: true
    }
  },
  {
    path: ROOT + 'logs',
    name: 'logs',
    component: Logs,
    meta: {
      perm: P_MONITORING_ALERTS,
      title: 'Logs',
      title_short: 'Logs',
      navigable: true,
      icon: icons.I_LOGS,
      category: CATEGORY,
      requiresAuth: true
    }
  },
  {
    path: ROOT + 'providers',
    name: 'live_providers',
    component: Providers,
    meta: {
      perm: P_MONITORING_PROVIDERS,
      title: 'Proveedores',
      title_short: 'Proveedores',
      navigable: true,
      icon: icons.I_PROVIDERS,
      category: CATEGORY,
      requiresAuth: true
    }
  },
  {
    path: ROOT + 'hosts',
    name: 'hosts',
    component: Hosts,
    meta: {
      perm: P_MONITORING_HOSTS,
      title: 'Gestión de los servidores',
      title_short: 'Servidores',
      navigable: true,
      icon: icons.I_HOSTS,
      category: CATEGORY,
      requiresAuth: true
    }
  }
]

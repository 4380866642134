<template>
  <v-container
    v-if="!loading"
  >
    <right-sidebar-standard-row
      v-for="(row, index) in standardRowsTop"
      :key="index"
      :name="row.name"
      :value="row.value"
    />
    <v-alert
      v-if="templateAlert"
      :type="templateAlert.type"
      class="my-2"
      dense
      icon="warning"
    >
      {{ templateAlert.message }}
    </v-alert>
    <v-row
      class="mt-4"
      justify="space-between"
    >
      <v-col
        class="pa-0 my-auto"
        cols="6"
      >
        <v-row>
          <v-col cols="10">

            <v-select
              v-model="versionSelected"
              :items="templateVersions"
              hide-details
              item-text="version"
              item-value="version"
              outlined
              placeholder="Seleccionar versión"
              return-object
              single-line
            >
              <template v-slot:item="{item}">
                <v-icon
                  :color="model_template.getTemplateColor(item).color"
                  class="mr-2"
                  small
                >label
                </v-icon>
                Versión {{ item.version }}
                <span
                  v-if="templateVersions.filter(tv => tv.tag == item.tag)[0].version == item.version"
                  class="ml-2"
                >(ACTIVA)</span>
              </template>
              <template v-slot:selection="{item}">
                <v-icon
                  :color="model_template.getTemplateColor(item).color"
                  class="mr-2"
                  small
                >label
                </v-icon>
                Versión {{ item.version }}
                <span
                  v-if="templateVersions.filter(tv => tv.tag == item.tag)[0].version == item.version"
                  class="ml-2"
                >(ACTIVA)</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="cb-btn-actions"
              height="28px"
              outlined
              width="28px"
              @click="jsonView = !jsonView"
            >
              <v-icon
                :title="jsonView ? 'Ver vista previa':'Ver código'"

                style="font-size: 14px !important;"
                v-text="jsonView ? 'visibility':'code'"
              ></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="pl-4"
        cols="6"
      >
        <v-row>
          <span class="font-weight-medium">
            {{ templateInfo[model_template.VERSION_UPLOADED_BY] }}
          </span>
        </v-row>
        <v-row
        >
          <span>
            {{ templateInfo[model_template.VERSION_CHANGELOG] }}
          </span>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-2"/>
    <v-container>
      <v-row v-if="jsonView">
        <v-jsoneditor
          v-model="templateData"
          :options="{mode:'code'}"
          height="60vh"
        />
      </v-row>
      <v-row v-else>

        <v-expansion-panels
          v-model="panel"
          class="ac-sidebar-expansion-panels"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>
                <v-icon class="mr-2">input</v-icon>
                Parámetros de entrada
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                v-for="(param, key,index) in templateData.parameters"
                :key="index"
                class="cb-row-bordered-bottom justify-space-between py-2"
              >
                <span
                  style="width: 70%"
                >
                  <v-chip
                    :color="model_template.getParamTypeFormatted(param.type).color"
                    :title="param.type == 'list' ? param.validations.allowed_values.join(' | '): param.type"
                    class="mr-2 px-1"
                    label
                    outlined
                    small
                  >
                    <span class="font-weight-medium">{{ model_template.getParamTypeFormatted(param.type).name }}</span>
                  </v-chip>
                  <span
                    :title="key"
                  >{{ key }}
                    <v-icon

                      v-if="param.validations['allow_empty'] != null && param.validations['allow_empty'] === false"
                      class="pb-2"
                      color="error"
                      style="font-size: 10px !important;"
                    >emergency</v-icon>
                  </span>
                </span>
                <span
                  class=" d-inline-block text-truncate my-auto"
                >
                  <strong>{{ model_template.getParamUsages(key, templateData) }}</strong> uso/s
                </span>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>
                <v-icon class="mr-2">timeline</v-icon>
                Pasos de la plantilla
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-if="!templateData.steps || templateData.steps.length == 0">Sin pasos en la plantilla</span>
              <v-row
                v-for="(step, index) in templateData.steps"
                :key="index"
                class="cb-row-bordered-bottom py-2"
              >
                  <span
                    class="caption d-inline-block text-truncate my-auto"
                    style="width: 70%"
                  >
                    <span
                      class="font-weight-medium my-auto"
                    >{{ step.step_code }}
                    </span>
                    <span class="mx-1">|</span>
                    <span
                      class="text-truncate my-auto"
                    >{{ step.worker_code }}
                    </span>
                  </span>

                <span
                  class="caption d-inline-block text-truncate my-auto text-right"
                  style="width: 30%"
                >
                      <v-chip
                        :color="step.locks != 0 ? 'error' : 'success'"
                        class="mr-1"
                        label
                        outlined
                        small
                        title="Candados"
                      >
                        <v-icon
                          class="mr-1"
                          small
                        >{{ step.locks != 0 ? 'lock' : 'lock_open' }}</v-icon>
                        <span class="cb-font-size-5">{{ step.locks.length }}</span>
                      </v-chip>
                      <v-chip
                        :color="step.max_times != 1 ? 'purple' : 'black'"
                        class="ml-1 "
                        label
                        outlined
                        small
                        title="Repeticiones máximas"
                      >
                        <v-icon
                          class="mr-1"
                          small
                        >refresh</v-icon>
                        <span
                          class="cb-font-size-5"
                        >{{ step.max_times }}</span>
                      </v-chip>
                  </span>

              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

  </v-container>
  <loading-container
    v-else
    :small-screen="true"
  />
</template>

<script>
import service_templates from '@/services/service_templates'
import LoadingContainer from '@/components/repository/LoadingContainer.vue'
import model_template from '@/constants/models/model_template'
import service_processes from '@/services/service_processes'
import model_process from '@/constants/models/model_process'
import RightSidebarStandardRow from '@/components/global_app/rightSidebarPreview/rightSidebarStandardRow.vue'

export default {
  name: 'rightSidebarSectionTemplate',
  components: { RightSidebarStandardRow, LoadingContainer },
  props: {
    code: String
  },
  data() {
    return {
      model_template,
      loading: true,
      jsonView: false,
      panel: [0, 1],
      versionSelected: null,
      templateProcesses: [],
      templateVersions: [],
      templateInfo: null,
      templateData: null
    }
  },
  computed: {
    standardRowsTop() {
      if (this.templateVersions == null || this.templateData == null) return []
      return [
        {
          name: 'Descripción',
          value: this.templateData.metadata[model_template.DESCRIPTION]
        },
        {
          name: 'Última versión',
          value: this.templateVersions[0].version + '<span class="' + this.model_template.getTemplateColor(this.templateVersions[0]).color + '--text"> (' +
            this.model_template.getTemplateColor(this.templateVersions[0]).name + ')</span> - <span class="font-italic">' + this.$moment(this.templateVersions[0][model_template.VERSION_LAST_CHANGE]).from() + '</span>'
        },
        {
          name: 'Procesos',
          value: this.templateProcesses.length
        },
      ]
    },
    templateAlert() {
      if (this.productionVersions != null && this.productionVersions.length == 0) {
        return {
          type: 'error',
          message: 'La plantilla no tiene versión de producción'
        }
      }
      if (this.productionVersions != null && this.templateVersions[0] != null && this.productionVersions[0].version != this.templateVersions[0].version) {
        return {
          type: 'warning',
          message: 'La versión en producción no es la más reciente. Versión de producción: ' + this.productionVersions[0].version + ', versión más reciente: ' + this.templateVersions[0].version
        }
      }
      return null
    },
    productionVersions() {
      return this.templateVersions.filter(tv => tv.tag == 'PRODUCCION').sort((a, b) => a.id < b.id)
    },
    developVersions() {
      return this.templateVersions.filter(tv => tv.tag == 'DESAROLLO').sort((a, b) => a.id < b.id)
    },
    testVersions() {
      return this.templateVersions.filter(tv => tv.tag == 'TEST').sort((a, b) => a.id < b.id)
    }
  },
  watch: {
    versionSelected(value) {
      this.getTemplateInfo(value)
    }
  },
  async created() {
    await this.getProcessesInfo()
    await this.getTemplateVersions()
    await this.getTemplateInfo(this.templateVersions[0])
  },
  methods: {
    changeSection(section) {
      this.section = section
    },
    async getTemplateVersions() {
      this.templateVersions = await service_templates.getTemplateVersions(this.code)
    },
    async getTemplateInfo(version) {
      this.loading = true
      this.versionSelected = version
      this.templateInfo = await service_templates.getTemplateInfo(this.code, version.version)
      this.templateData = JSON.parse(this.templateInfo[model_template.TEMPLATE_STRING])
      this.loading = false
    },

    async getProcessesInfo() {
      service_processes.findProcesses([model_process.NAME, model_process.CODE, model_process.MASTER_ACCOUNT_CODE, model_process.MASTER_ACCOUNT_NAME],
        {
          [model_process.TEMPLATE_CODE]: this.code
        }).then(extra => {
        this.templateProcesses = extra.results
      })

    },
  }
}
</script>

<style lang="scss">
@import "../../../../assets/styles/resources/colors";

</style>
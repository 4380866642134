<template>
  <v-col class="pa-0 my-auto cb-header-search-bar">
    <v-row>
      <v-select
        v-if="service_user.checkGroupPermission(P_INSTANCES)"
        v-model="modelType"
        :items="modelTypes"
        class="my-auto mr-0"
        dense
        hide-details
        item-text="name"
        item-value="code"
        label=""
        outlined
        return-object
        style="margin-right: -1px !important"
      >
        <template v-slot:selection>
          <span v-if="modelType.code == 'auto'">
            Auto
          </span>
          <span v-else>
            {{ modelType.name }}
          </span>
        </template>
      </v-select>
      <v-text-field
        v-if="service_user.checkGroupPermission(P_INSTANCES)"
        ref="searchBar"
        v-model="modelValue"
        append-icon="search"
        class="my-auto"
        clearable
        dense
        hide-details
        outlined
        placeholder="Buscar en APP Center"
        @click:append="searchItem()"
        @keydown.enter="searchItem()"
      />
    </v-row>
  </v-col>
</template>

<script>
import service_instances from '@/services/service_instances'
import helpers from '@/plugins/helpers'
import service_processes from '@/services/service_processes'
import model_process from '@/constants/models/model_process'
import service_templates from '@/services/service_templates'
import model_template from '@/constants/models/model_template'
import model_masteraccount from '@/constants/models/model_masteraccount'
import service_masteraccounts from '@/services/service_masteraccounts'
import vuex_protocol from '@/store/modules/sections/vuex_protocol'
import service_user from '@/services/service_user'
import { P_INSTANCES } from '@/constants/group_permissions'

export default {
  name: 'GlobalSearchBar',
  data() {
    return {
      service_user,
      P_INSTANCES,
      modelValue: '',
      modelType: '',
      modelTypes: [
        { code: 'auto', name: 'Automático' },
        { code: 'master_account', name: 'C. Raíz' },
        { code: 'instance', name: 'Instancia' },
        { code: 'process', name: 'Proceso' },
        { code: 'template', name: 'Plantilla' },
        { code: 'postamail_id', name: 'ID Postal' },
      ]
    }
  },
  computed: {
    modelTypeCalculated() {
      let prefix = this.modelValue.substring(0, 2)
      if (prefix == 'p:' || (prefix == 'PS' && this.modelValue.length == 7)) {
        return this.modelTypes.find(mt => mt.code == 'process')
      }
      if (prefix == 'i:' || (prefix == 'PS' || this.modelValue.split('.').length == 3 && this.modelValue.length >= 40)) {
        return this.modelTypes.find(mt => mt.code == 'instance')
      }
      if (prefix == 'm:' || (prefix == 'MA' && this.modelValue.length == 7)) {
        return this.modelTypes.find(mt => mt.code == 'master_account')
      }
      if (prefix == 't:') {
        return this.modelTypes.find(mt => mt.code == 'template')
      }
      if (prefix == 'c:') {
        return this.modelTypes.find(mt => mt.code == 'postamail_id')
      }
      return this.modelTypes[0]
    }
  },
  created() {
    this.modelType = this.modelTypes[0]
  },
  methods: {
    searchItem() {
      if (this.modelValue.indexOf(':') == -1) {
        if ((this.modelValue.substring(0, 2) == 'PS' || this.modelValue.split('.').length == 3) && this.modelValue.length >= 40) {
          service_instances.getInstanceDetails(this.modelValue).then(extra => {
            if (extra == null) {
              helpers.setAlertMessage(1, 'No se encontró la instancia')
            } else {

              let route = this.$route.name
              helpers.redirect('instances', null, {
                id: this.modelValue,
                name: this.modelValue
              })
              if (route === 'instances') {
                window.location.reload()
              }
            }
          })
        } else if (this.modelValue.substring(0, 2) == 'PS' && this.modelValue.length == 7) {
          service_processes.getProcessInfo(this.modelValue).then(response => {
            if (response.data.error != 0) {
              helpers.setAlertMessage(1, 'No se encontró el proceso')
            } else {
              let route = this.$route.name
              helpers.redirect('processes', null, {
                id: this.modelValue,
                name: response.data.extra[model_process.NAME]
              })
              if (route === 'processes') {
                this.$router.go(this.$router.currentRoute)
              }
            }
          })
        } else if (this.modelValue.substring(0, 2) == 'MA' && this.modelValue.length == 7) {
          service_masteraccounts.findMasterAccounts([
            model_masteraccount.CODE, model_masteraccount.NAME
          ], { [model_masteraccount.CODE]: this.modelValue }).then(extra => {
            if (extra.results.length == 0) {
              helpers.setAlertMessage(1, 'No se encontró la cuenta raíz')
            } else {
              let ma = extra.results[0]
              service_masteraccounts.openMasterAccount(ma[model_masteraccount.CODE], ma[model_masteraccount.NAME])

            }
          })
        } else if (this.modelValue.length < 10 && this.modelValue.length >= 5) {
          service_instances.getInstanceFromPostamailId(this.modelValue).then(instance_code => {
            this.modelValue = instance_code
            this.searchItem()
          })
        } else {
          service_templates.getTemplateInfo(this.modelValue, 1).then(template_info => {
            if (!helpers.nullOrEmpty(template_info)) {
              service_templates.openTemplate(template_info[model_template.CODE])
            } else {
              helpers.setAlertMessage(1, 'No se encontró ninguna coincidencia')
            }
          })
        }
      } else {
        if (this.modelValue.substring(0, 2).toUpperCase() == 'M:') {
          let codeOrName = this.modelValue.split(':')[1]
          if (codeOrName != null) {
            let filters = {
              'OR': {

                [model_masteraccount.CODE + '[~]']: codeOrName,
                [model_masteraccount.NAME + '[~]']: codeOrName
              }
            }
            service_masteraccounts.findMasterAccounts([model_masteraccount.CODE, model_masteraccount.NAME], filters, null).then(data => {
              if (data.results.length == 1) {

                service_masteraccounts.openMasterAccount(
                  data.results[0][model_masteraccount.CODE], data.results[0][model_masteraccount.NAME]
                )
              } else if (data.results.length > 1) {

                this.$store.dispatch(vuex_protocol.constants.NAMESPACE + '/' +
                  vuex_protocol.constants.A_SET_SEARCH_FILTERS_MASTER_ACCOUNTS, filters).then(() => {
                  helpers.redirect('accounts')
                })
              } else {
                helpers.setAlertMessage(1, 'No se ha encontrado la cuenta')
              }
            })
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">

.cb-header-search-bar {
  & .v-select {
    display: none;
  }
}

@media (min-width: 960px) {

  .cb-header-search-bar {
    & .v-select {
      max-width: 100px;
      width: 100px;
    }

    & .v-text-field {
      width: 300px;
      max-width: 300px;
    }
  }
}

@media (min-width: 1367px) {


  .cb-header-search-bar {
    & .v-select {
      display: inline;
      max-width: 100px !important;
      width: 100px !important;
    }

    & .v-text-field {
      width: 400px;
      max-width: 400px;
    }
  }

}
</style>

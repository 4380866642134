import Vue from 'vue'
import model_group from '@/constants/models/app_center/model_group'
import jwt_decode from "vue-jwt-decode";

const constants = Object.freeze({
    NAMESPACE: 'vuex_authentication',

    S_USERNAME: 'state_username',
    S_NAME: 'state_name',
    S_ID: 'state_id',
    S_GROUP: 'state_group',
    S_EXPIRATION_TIMEOUT: 'state_expiration_timeout',
    S_SESSION_EXPIRED: 'state_session_expired',
    S_SESSION_EXPIRED_COMPLETELY: 'state_session_expired_completely',

    M_SET_USERNAME: 'mutation_set_username',
    M_SET_NAME: 'mutation_set_name',
    M_SET_ID: 'mutation_set_id',
    M_SET_GROUP: 'mutation_set_group',
    M_EXPIRATION_TIMEOUT: 'mutate_expiration_timeout',
    M_SESSION_EXPIRED: 'mutate_session_expired',
    M_SESSION_EXPIRED_COMPLETELY: 'mutate_session_expired_completely',

    A_SET_LOGIN: 'action_set_login',
    A_SET_LOGOUT: 'action_set_logout',
    A_SET_EXPIRED: 'action_set_expired',
    A_SET_EXPIRATION_TIMEOUT: 'action_set_expiration_timeout',
    A_SET_SESSION_EXPIRED: 'action_set_session_expired',
    A_SET_SESSION_EXPIRED_COMPLETELY: 'action_set_session_expired_completely',
    A_REFRESH_EXPIRATION: 'action_refresh_expiration',

    G_LOGIN_DATA: 'getter_login',
    G_EXPIRATION_TIMEOUT: 'getter_session_expiration_timeout',
})

const state = {
    [constants.S_USERNAME]: null,
    [constants.S_NAME]: null,
    [constants.S_GROUP]: null,
    [constants.S_EXPIRATION_TIMEOUT]: null,
    [constants.S_SESSION_EXPIRED]: false,
    [constants.S_SESSION_EXPIRED_COMPLETELY]: false,
}

const mutations = {
    [constants.M_SET_NAME](state, name) {
        state[constants.S_NAME] = name
    },
    [constants.M_SET_ID](state, id) {
        state[constants.S_ID] = id
    },
    [constants.M_SET_USERNAME](state, username) {
        state[constants.S_USERNAME] = username
    },
    [constants.M_SET_GROUP](state, group) {
        state[constants.S_GROUP] = group
    },

    [constants.M_EXPIRATION_TIMEOUT](state, timeout) {
        state[constants.S_EXPIRATION_TIMEOUT] = timeout
    },

    [constants.M_SESSION_EXPIRED](state, expired) {
        state[constants.S_SESSION_EXPIRED] = expired
    },

    [constants.M_SESSION_EXPIRED_COMPLETELY](state, expired) {
        state[constants.S_SESSION_EXPIRED_COMPLETELY] = expired
    },
}

const actions = {
    [constants.A_SET_LOGIN](state, data) {
        Vue.delete(data.group, model_group.PERMISSIONS)
        state.commit(constants.M_SET_NAME, data.name)
        state.commit(constants.M_SET_USERNAME, data.username)
        state.commit(constants.M_SET_GROUP, data.group)
        state.commit(constants.M_SET_ID, data.id)
        return true
    },
    [constants.A_SET_LOGOUT](state, notReload) {
        state.commit(constants.M_SET_NAME, null)
        state.commit(constants.M_SET_USERNAME, null)
        state.commit(constants.M_SET_GROUP, null)
        localStorage.removeItem('vuex')
        localStorage.removeItem('vuex-multi-tab')
        Vue.$cookies.remove('act')
        if (!notReload) {
            window.location.reload()
        }
    },


    [constants.A_SET_EXPIRATION_TIMEOUT]({commit}, timeoutObject) {
        commit(constants.M_EXPIRATION_TIMEOUT, timeoutObject)
    },

    [constants.A_SET_SESSION_EXPIRED]({commit}, value) {
        commit(constants.M_SESSION_EXPIRED, value)
    },

    [constants.A_SET_SESSION_EXPIRED_COMPLETELY]({commit}, value) {
        commit(constants.M_SESSION_EXPIRED_COMPLETELY, value)
    }
}

const getters = {
    [constants.G_LOGIN_DATA](state) {
        let token = jwt_decode.decode('act')

        return {
            username: state[constants.S_USERNAME],
            name: state[constants.S_NAME],
            group: state[constants.S_GROUP],
            token: token != null ? token : null
        }
    },

    [constants.G_EXPIRATION_TIMEOUT](state) {
        return state[constants.S_EXPIRATION_TIMEOUT]
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    constants,
}

export const P_LOGS = 'logs'
export const P_EXPORT = 'export'
export const P_DOWNLOAD_FILES = 'download_files'
export const P_ACCOUNT = 'account'
export const P_INSTANCES = 'instances'
export const P_INSTANCE_EVENT_DETAILS = 'instance_event_details'
export const P_INSTANCE_DOCUMENT = 'instance_document'
export const P_INSTANCE_RESOURCES = 'instance_resources'
export const P_INSTANCE_REDIS = 'instance_redis'
export const P_INSTANCE_CALLBACKS = 'instance_callbacks'
export const P_INSTANCE_API = 'instance_api'
export const P_INSTANCE_DOWNLOAD_CERTIFICATE = 'instance_download_certificate'
export const P_INSTANCE_DOWNLOAD_BACKUP = 'instance_download_backup'
export const P_TEMPLATES = 'templates'
export const P_TEMPLATE_HISTORY = 'template_history'
export const P_TEMPLATE_EDIT = 'template_edit'
export const P_PROCESSES = 'processes'
export const P_PROCESS_CALLBACKS = 'process_callbacks'
export const P_PROCESS_RESOURCES = 'process_resources'
export const P_PROCESS_RESOURCE_VARIABLE_EDIT = 'process_resource_variable_edit'
export const P_PROCESS_RESOURCE_FILE_EDIT = 'process_resource_file_edit'
export const P_PROCESS_LANGUAGES_EDIT = 'process_languages_edit'
export const P_PROCESS_LAUNCH_TEST = 'process_launch_test'
export const P_PROCESS_LAUNCH_CHANGE_VERSION = 'process_launch_change_version'
export const P_PROCESS_CONFIG = 'process_config'
export const P_PROCESS_CONFIG_PROVIDERS = 'process_config_providers'
export const P_PROCESS_CONFIG_B2B = 'process_config_b2b'
export const P_PROCESS_CONFIG_API = 'process_config_api'
export const P_PROCESS_CONFIG_SECURITY = 'process_config_security'
export const P_PROCESS_CONFIG_EVENTS = 'process_config_events'
export const P_PROCESS_CONFIG_CALLBACKS = 'process_config_callbacks'
export const P_PROCESS_DOWNLOAD_DOCUMENTATION = 'process_download_documentation'
export const P_PROCESS_DOWNLOAD_SWAGGER = 'process_download_swagger'
export const P_ADMINISTRATION = 'administration'
export const P_MONITORING_ALERTS = 'monitoring_alerts'
export const P_MONITORING_ALERT_RETRY = 'monitoring_alert_retry'
export const P_MONITORING_ALERT_SOFT_REJECT_DELETE = 'monitoring_alert_soft_reject_delete'
export const P_MONITORING_ALERT_ERROR_HANDLER_DELETE = 'monitoring_alert_error_handler_delete'
export const P_MONITORING_ALERT_HARD_REJECT_DELETE = 'monitoring_alert_hard_reject_delete'
export const P_MONITORING_ALERT_REDIS_RETRY = 'monitoring_alert_redis_retry'
export const P_MONITORING_ALERT_REDIS_DELETE = 'monitoring_alert_redis_delete'
export const P_MONITORING_STATS = 'monitoring_stats'
export const P_MONITORING_PROVIDERS = 'monitoring_providers'
export const P_MONITORING_HOSTS = 'monitoring_hosts'
export const P_MONITORING_HOSTS_STOP_WORKERS = 'monitoring_hosts_stop_workers'
export const P_B2B_USERS_ACTIONS = 'b2b_users_actions'
export const P_PROTOCOL_ACCOUNTS = 'protocol_accounts'
export const P_PROTOCOL_ACCOUNTS_EDIT = 'protocol_accounts_edit'
export const P_PROTOCOL_PROVIDERS = 'protocol_providers'
export const P_PROTOCOL_PROVIDERS_EMAIL = 'protocol_providers_email'
export const P_PROTOCOL_PROVIDERS_POSTAMAIL = 'protocol_providers_postamail'
export const P_PROTOCOL_PROVIDERS_SMS = 'protocol_providers_sms'

export const P_PROTOCOL_BILLING_UNITS_EDIT = 'protocol_accounts_billing_units_edit'
export const P_PROTOCOL_EMAIL_CHECKER = 'protocol_email_checker'
export const P_PROTOCOL_EMAIL_CHECKER_EDIT = 'protocol_email_checker_edit'


export const P_PROTOCOL_ACCOUNTS_B2B_USERS = 'protocol_accounts_b2b_users'
export const P_PROTOCOL_ACCOUNTS_B2B_GROUPS = 'protocol_accounts_b2b_groups'
export const P_PROTOCOL_ACCOUNTS_BILLING_UNITS = 'protocol_accounts_billing_units'
export const P_PROTOCOL_ACCOUNTS_B2B_USERS_EDIT = 'protocol_accounts_b2b_users_edit'
export const P_PROTOCOL_ACCOUNTS_B2B_GROUPS_EDIT = 'protocol_accounts_b2b_groups_edit'
export const P_PROTOCOL_ACCOUNTS_BILLING_UNITS_EDIT = 'protocol_accounts_billing_units_edit'
export const P_PROTOCOL_ACCOUNTS_EDIT_POSTAMAIL = 'protocol_accounts_edit_postamail'
export const P_PROTOCOL_ACCOUNTS_EDIT_B2B = 'protocol_accounts_edit_b2b'
export const P_PROTOCOL_SUBACCOUNTS = 'protocol_subaccounts'
export const P_PROTOCOL_SUBACCOUNTS_EDIT = 'protocol_subaccounts_edit'
export const P_PROTOCOL_SUBACCOUNTS_EDIT_PROJECTS = 'protocol_subaccounts_edit_projects'
export const P_PROTOCOL_ACCOUNTS_ALASTRIA = 'protocol_accounts_alastria'

export const P_PROTOCOL_FILE_EXPLORER = 'protocol_file_explorer'
export const P_PROTOCOL_FILE_EXPLORER_EDIT = 'protocol_file_explorer_edit'
export const P_PROTOCOL_BILLING = 'protocol_billing'
export const P_PROTOCOL_BILLING_PROVIDERS = 'protocol_billing_providers'
export const P_PROTOCOL_BILLING_CUSTOM_LAUNCH = 'protocol_billing_custom_launch'
export const P_PROTOCOL_ENTITIES = 'protocol_entities'
export const P_PROTOCOL_ENTITIES_COMMSTRACKER = 'protocol_entities_commstracker'
export const P_PROTOCOL_ENTITIES_POSTAL_ENVELOPES = 'protocol_entities_postal_envelopes'
export const P_PROTOCOL_ENTITIES_CERTIFICATE_ISSUERS = 'protocol_entities_certificate_issuers'
export const P_PROTOCOL_SCHEDULE_TASKS = 'protocol_schedule_tasks'
export const P_PROTOCOL_SCHEDULE_TASKS_EDIT = 'protocol_schedule_tasks_edit'
export const P_PROTOCOL_TICKER = 'protocol_ticker'
export const P_PROTOCOL_GLOBAL_VARIABLES = 'protocol_global_variables'
export const P_PROTOCOL_GLOBAL_VARIABLES_EDIT = 'protocol_global_variables_edit'
export const P_PROTOCOL_ENTITIES_POSTAL_ENVELOPES_EDIT = 'protocol_entities_postal_envelopes_edit'
export const P_PROTOCOL_ENTITIES_CERTIFICATE_ISSUERS_EDIT = 'protocol_entities_certificate_issuers_edit'
export const P_PROTOCOL_ENTITIES_APPEARANCES = 'protocol_entities_appearances'
export const P_PROTOCOL_ENTITIES_APPEARANCES_EDIT = 'protocol_entities_appearances_edit'
export const P_MONITORING_NOTIFICATIONS = 'monitoring_notifications'
export const P_PROTOCOL_PROVIDERS_EDIT = 'protocol_providers_edit'
export const P_PROTOCOL_NOTARIAL_DEPOSIT = 'protocol_notarial_deposit'//'protocol_notarial_deposit'
export const P_PROTOCOL_NOTARIAL_DEPOSIT_UPDATE = 'protocol_notarial_deposit_update_record'
export const P_MONITORING_BATCH_UPLOADS = 'batch_uploader_view'

export const P_COMMSERVICES_USERS_LIST = 'commservices_users'
export const P_COMMSERVICES_USERS_EDIT = 'commservices_users_edit'
export const P_COMMSERVICES_COMMSTRACKER_LIST = 'commservices_commstracker'
export const P_COMMSERVICES_COMMSTRACKER_EDIT = 'commservices_commstracker_edit'
export const P_COMMSERVICES_EXCLUSION_LIST = 'commservices_exclusion_list'
export const P_COMMSERVICES_EXCLUSION_LIST_EDIT = 'commservices_exclusion_list_edit'

export const P_SHOW_SIDEBAR = 'show_sidebar'

export function getGroupPermissionsFromCategory(category) {
    switch (category) {
        case 'global': {
            return [
                P_LOGS,
                P_EXPORT,
                P_DOWNLOAD_FILES,
                P_ACCOUNT,
                P_ADMINISTRATION,
                P_SHOW_SIDEBAR
            ]
        }
        case 'monitoring' : {
            return [
                P_MONITORING_ALERTS,
                P_MONITORING_STATS,
                P_MONITORING_PROVIDERS,
                P_MONITORING_HOSTS,
                P_MONITORING_NOTIFICATIONS,
                P_MONITORING_BATCH_UPLOADS
            ]
        }
        case 'operations' : {
            return [
                P_INSTANCES,
                P_TEMPLATES,
                P_PROCESSES,
                P_PROTOCOL_ACCOUNTS,
            ]
        }
        case 'commservices' : {
            return [
                P_COMMSERVICES_USERS_LIST,
                P_COMMSERVICES_USERS_EDIT,
                P_COMMSERVICES_COMMSTRACKER_LIST,
                P_COMMSERVICES_COMMSTRACKER_EDIT,
                P_COMMSERVICES_EXCLUSION_LIST,
                P_COMMSERVICES_EXCLUSION_LIST_EDIT
            ]
        }
        case 'protocol' : {
            return [
                P_PROTOCOL_PROVIDERS,
                P_PROTOCOL_ACCOUNTS_BILLING_UNITS,
                P_PROTOCOL_SUBACCOUNTS,
                P_PROTOCOL_ENTITIES,
                P_PROTOCOL_BILLING,
                P_PROTOCOL_FILE_EXPLORER,
                P_PROTOCOL_GLOBAL_VARIABLES,
                P_PROTOCOL_SCHEDULE_TASKS,
                P_PROTOCOL_TICKER,
                P_PROTOCOL_NOTARIAL_DEPOSIT,
                P_PROTOCOL_PROVIDERS_EDIT
            ]
        }
    }
}


export default {
    P_LOGS,
    P_EXPORT,
    P_DOWNLOAD_FILES,
    P_ACCOUNT,
    P_INSTANCES,
    P_INSTANCE_EVENT_DETAILS,
    P_INSTANCE_DOCUMENT,
    P_INSTANCE_RESOURCES,
    P_INSTANCE_REDIS,
    P_INSTANCE_CALLBACKS,
    P_INSTANCE_API,
    P_INSTANCE_DOWNLOAD_CERTIFICATE,
    P_INSTANCE_DOWNLOAD_BACKUP,
    P_TEMPLATES,
    P_TEMPLATE_HISTORY,
    P_TEMPLATE_EDIT,
    P_PROCESSES,
    P_PROCESS_CALLBACKS,
    P_PROCESS_RESOURCES,
    P_PROCESS_RESOURCE_VARIABLE_EDIT,
    P_PROCESS_RESOURCE_FILE_EDIT,
    P_PROCESS_LANGUAGES_EDIT,
    P_PROCESS_LAUNCH_TEST,
    P_PROCESS_LAUNCH_CHANGE_VERSION,
    P_PROCESS_CONFIG,
    P_PROCESS_CONFIG_PROVIDERS,
    P_PROCESS_CONFIG_B2B,
    P_PROCESS_CONFIG_API,
    P_PROCESS_CONFIG_SECURITY,
    P_PROCESS_CONFIG_EVENTS,
    P_PROCESS_CONFIG_CALLBACKS,
    P_PROCESS_DOWNLOAD_DOCUMENTATION,
    P_PROCESS_DOWNLOAD_SWAGGER,
    P_ADMINISTRATION,
    P_MONITORING_ALERTS,
    P_MONITORING_ALERT_RETRY,
    P_MONITORING_STATS,
    P_MONITORING_PROVIDERS,
    P_MONITORING_HOSTS,
    P_MONITORING_HOSTS_STOP_WORKERS,
    P_B2B_USERS_ACTIONS,
    P_PROTOCOL_ACCOUNTS,
    P_PROTOCOL_ACCOUNTS_EDIT,
    P_PROTOCOL_PROVIDERS,
    P_PROTOCOL_PROVIDERS_EMAIL,
    P_PROTOCOL_PROVIDERS_POSTAMAIL,
    P_PROTOCOL_PROVIDERS_SMS,
    P_PROTOCOL_ACCOUNTS_BILLING_UNITS,
    P_PROTOCOL_BILLING_UNITS_EDIT,
    P_PROTOCOL_BILLING_CUSTOM_LAUNCH,
    P_PROTOCOL_BILLING_PROVIDERS,
    P_MONITORING_BATCH_UPLOADS,
    P_PROTOCOL_PROVIDERS_EDIT,
    P_MONITORING_ALERT_SOFT_REJECT_DELETE,
    P_MONITORING_ALERT_ERROR_HANDLER_DELETE,
    P_MONITORING_ALERT_HARD_REJECT_DELETE,
    P_MONITORING_ALERT_REDIS_RETRY,
    P_MONITORING_ALERT_REDIS_DELETE,
    getGroupPermissionsFromCategory
}

<template>

    <v-col v-if="eventSelected" class="pa-0 cb-event-detail-col">

        <v-row class="cb-dialog-title-row" style="background-color: white">
            <v-col class="pa-2 ">
                <v-row justify="space-between">
                    <h1>
                      <span class="title font-weight-medium">
                        {{eventSelected.step_event.toUpperCase().split('.')[0]}}
                      </span>
                        |
                        <span class="cb-text-descriptor">
                        {{eventSelected.step_event.toUpperCase().split('.')[1]}}
                      </span>
                    </h1>
                    <span class="font-weight-medium">{{
                            $moment.unix(eventSelected.timestamp).format('DD/MM/YY HH:mm:ss')
                        }}</span>
                </v-row>
                <v-row
                  class=""
                  justify="space-between"
                >
                    <span class="pt-1">{{eventSelected.event_code}}</span>
                    <span>{{$moment.unix(eventSelected.timestamp).from()}}</span>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="px-2">
            <v-btn-toggle v-model="eventSelectedTab" active-class="primary" dense mandatory>
                <v-btn
                  v-for="tab in availableTabs" :id="tab.code"
                  :key="tab.code"
                  :value="tab.code"
                  small
                  width="100px"
                  @click="changeTab(tab)">{{tab.name}}
                </v-btn>
            </v-btn-toggle>
        </v-row>
        <v-row>
            <v-tabs-items
              v-model="eventSelectedTab"
              class="cts-w-100 pa-2"
            >
                <v-tab-item
                  v-for="tab in availableTabs"
                  :id="tab.code"
                  :key="tab.code"
                  :transition="false"
                  class="cts-w-100"
                >
                    <v-row
                      v-if="tab.code == 'input_variables'"
                      class="ma-0"
                      dense
                    >
                        <VarsViewer
                          :instance_code="instance_code"
                          :variables="eventSelected.input_variables"
                        />
                    </v-row>
                    <v-row
                      v-if="tab.code == 'output_variables'"
                      class="ma-0"
                      dense
                    >
                        <VarsViewer
                          :instance_code="instance_code"
                          :variables="eventSelected.output_variables"
                        />
                    </v-row>
                    <v-row
                      v-if="tab.code == 'evidences'"
                      class="ma-0"
                      dense
                    >
                        <VarsViewer
                          :instance_code="instance_code"
                          :variables="eventSelected.evidences"
                        />
                    </v-row>
                    <v-row
                      v-if="tab.code == 'intervener'"
                      class="ma-0"
                      dense
                    >
                        <v-jsoneditor
                          v-model="intervener"
                          :options="{mode:'code'}"
                          height="58vh"
                        />
                    </v-row>
                    <v-row
                      v-if="tab.code == 'json'"
                      class="ma-0"
                      dense
                    >
                        <v-jsoneditor
                          v-model="eventSelected"
                          :options="{mode:'code',mainMenuBar : false,statusBar : false}"
                          height="50vh"

                        />
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-row>
    </v-col>

</template>

<script>
import helpers from '@/plugins/helpers'
import VarsViewer from '@/components/explorers/instances/instance_details/components/VarsViewer'

export default {
    name: 'eventDetailsDialog',
    components: {VarsViewer},
    props: {
        instance_code: String,
        eventData: Object,
        intervener: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            eventDetailsOpen: true,
            eventSelectedTab: null,
            eventSelected: null
        }
    },
    mounted() {
        this.eventSelected = this.eventData
    },
    watch: {
        eventData(value) {
            this.eventSelected = value
        },
    },
    computed: {
        availableTabs() {
            let tabs = []
            if (!this.eventSelected) {
                return tabs
            }
            if (!helpers.nullOrEmpty(this.eventSelected.input_variables) && this.eventSelected.input_variables.length != 0) {
                tabs.push({
                    name: 'Entrada',
                    code: 'input_variables'
                })
            }
            if (!helpers.nullOrEmpty(this.eventSelected.output_variables) && this.eventSelected.output_variables.length != 0) {
                tabs.push({
                    name: 'Salida',
                    code: 'output_variables'
                })
            }
            if (!helpers.nullOrEmpty(this.eventSelected.evidences) && this.eventSelected.evidences.length != 0) {
                tabs.push({
                    name: 'Evidencias',
                    code: 'evidences'
                })
            }
            if (!helpers.nullOrEmpty(this.intervener)) {
                tabs.push({
                    name: 'Interviniente',
                    code: 'intervener'
                })
            }
            tabs.push({
                name: 'JSON',
                code: 'json'
            })
            this.setTab(tabs)
            return tabs
        }
    },
    methods: {
        setTab(tabs) {
            this.eventSelectedTab = tabs[0].code
        },
        changeTab(tab) {
            this.eventSelectedTab = tab.code
        },
        getEventTitle() {
            let arrName = this.eventSelected.step_event.toUpperCase().split('.')
            let title = ''
            if (arrName.length == 2) {
                title = '<span class="title font-weight-medium white--text">' + arrName[0] + '</span>  |  <span class="title">' + arrName[1] + '</span>'
            } else {
                title = this.eventSelected.step_event
            }
            title += ' <span class="title white--text">- ' + this.$moment.unix(this.eventSelected.timestamp).format('DD/MM/YY HH:mm:ss') + '</span>'

            return title
        },
        changeEvent(event) {
            this.eventSelected = event
        },
        close() {
            this.eventDetailsOpen = false
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
@import "../../../../../assets/styles/resources/colors";

.cb-transaction-event-dialog {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.cb-transaction-event-dialog.v-card {
    border: none !important;
}

.cb-transaction-event-dialog .header {
    flex: 0 1 auto;
}

.cb-transaction-event-dialog .content {
    flex: 1 1 auto;
}

.cb-transaction-event-dialog {
    min-height: 80vh;
    height: 80vh;
}

.cb-events-row {
    background-color: $color_app_contrast_low;
    cursor: pointer;
}

.cb-events-row-active {
    background-color: $color_app_bg_accent !important;
}

.cb-events-row-active span {
    color: $color_app_clear !important;
}

.cb-events-row:hover {
    background-color: $color_app_contrast_medium;
}

.cb-events-row:hover span {
    color: $color_app_bg_dark;
}

.cb-event-detail-col {
    height: 100%;
    overflow: auto;
}

.cb-events-col {
    background-color: $color_app_bg_clear;
    border-right: 1px solid $color_app_contrast_medium;
    overflow: auto;
    max-height: 100%;
}

.cb-tabs-header .v-tab {
    background-color: $color_app_contrast_medium;
}

.cb-tabs-header {
    position: sticky;
    top: 0px;
}

.cb-tabs-header .v-tab--active {
    background-color: rgba($color_app_bg_accent, 0.6) !important;
    color: $color_app_clear !important;
}

.cb-tabs-header .v-tabs-bar__content {
    height: 45px;
    border-bottom: 1px solid $color_app_contrast_low;
}

.cb-variable-row {
    border-bottom: 1px solid $color_app_contrast_medium;
}
</style>

<template>
		<v-navigation-drawer
			v-model="inputVal"
			class="acc-sidebar-preview"
			fixed
			right
			temporary
			width="33%"
		>
				<v-row
					class="acc-sidebar-preview-title"
					justify="space-between"
					style="position: sticky;top: 0;z-index: 1000"
				>
						<v-btn
							id="sidebar-close-button"
							color="primary"
							icon
							large
							title="Cerrar"
							@click="close"
						>
								<v-icon style="font-size: 24px !important;">arrow_forward</v-icon>
						</v-btn>
						<v-col
							class="text-center text-truncate"
							cols="7"
						>
        <span id="sidebar-item-type" class="my-auto cb-font-size-2"
        >{{$vuetify.lang.t('$vuetify.' + this.item.type)}}</span><br>
								<span
									id="sidebar-item-name"
									class="cb-font-size-2"
								>{{this.item.name}}</span>
						</v-col>
						<v-btn
							id="sidebar-open-in-new-button"
							color="primary"
							icon
							large
							title="Abrir en nueva pestaña"
							@click="openInNewTab"
						>
								<v-icon style="font-size: 24px !important;">open_in_new</v-icon>
						</v-btn>
				</v-row>
				<v-row>
						<v-container class="pa-0">
								<right-sidebar-section-template
									v-if="item.type=='template'"
									:code="item.code"
								/>
								<right-sidebar-section-instance
									v-else-if="item.type=='instance'"
									:code="item.code"
								/>
								<right-sidebar-section-process
									v-else-if="item.type=='process'"
									:code="item.code"
								/>
								<v-row
									v-else
									class="justify-center pt-8"
								>
										Previsualización no disponible
								</v-row>
						</v-container>
				</v-row>
		</v-navigation-drawer>
</template>

<script>
import RightSidebarSectionTemplate
		from '@/components/global_app/rightSidebarPreview/sections/rightSidebarSectionTemplate.vue'
import RightSidebarSectionInstance
		from '@/components/global_app/rightSidebarPreview/sections/rightSidebarSectionInstance.vue'
import RightSidebarSectionProcess
		from '@/components/global_app/rightSidebarPreview/sections/rightSidebarSectionProcess.vue'

export default {
		name: 'rightSidebarPreview',
		components: {RightSidebarSectionProcess, RightSidebarSectionInstance, RightSidebarSectionTemplate},
		props: {
				value: Boolean,
				item: Object
		},
		data() {
				return {
						loading: true,
						data: null
				}
		},
		computed: {
				inputVal: {
						get() {
								return this.value
						},
						set(val) {
								this.$emit('input', val)
						}
				}
		},
		created() {
				if (this.item.type != 'instance' && this.item.type != 'process' && this.item.type != 'template') {
						this.openInNewTab()
						this.close()
				}
		},
		methods: {
				close() {
						this.inputVal = false
				},
				openInNewTab() {
						this.$emit('openInNewTab', this.item)
				}
		}
}
</script>

<style lang="scss">
@import "../../assets/styles/resources/variables";
@import "../../assets/styles/resources/colors";

.acc-sidebar-preview span, p {
		font-size: $font-size-3;
}

.v-navigation-drawer .v-expansion-panel {
		border-radius: 0 !important;
}

.v-navigation-drawer .v-expansion-panel i {
		font-size: unset !important;
}

.acc-sidebar-preview .acc-sidebar-preview-title {
		border-bottom: 1px solid $color_app_contrast_medium;
		padding-top: 8px;
		padding-bottom: 8px;
		align-items: center;
		background-color: $color_app_clear;
}

.ac-sidebar-expansion-panels .v-expansion-panel:before {
		box-shadow: none;
}

.ac-sidebar-expansion-panels .v-expansion-panel-header {
		padding: 0 8px 0 8px;
		min-height: 36px !important;
		border-bottom: 1px solid $color_app_contrast_medium;

}

.ac-sidebar-expansion-panels .v-expansion-panel-content__wrap {
		padding: 12px;
}

.ac-sidebar-expansion-panels .v-expansion-panel-content__wrap .v-chip--label {
		width: 36px;
		justify-content: center;
		padding-left: 0;
		padding-right: 0;
}

.ac-sidebar-tabs .v-tab {
		border-bottom: 1px solid $color_app_contrast_medium;
}
</style>
import Axios from 'axios'
import service_explorers from '@/services/global/service_explorers'
import model_host from '@/constants/models/model_host'
import {objectToFormData} from 'object-to-formdata'
import service_user from '@/services/service_user'
import {P_MONITORING_HOSTS_STOP_WORKERS} from '@/constants/group_permissions'
import router from '@/router'
import model_appearance from '@/constants/models/protocol/model_appearance'

export default {
    getTemplates() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'templates').then(
            (response) => {
                return response.data
            }
        )
    },
    getLanguages() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/languages').then(
            (response) => {
                return response.data.extra.results
            }
        )
    },
    getAppearances(fields, filters, options) {
        return service_explorers.find('app-center/appearances', fields, filters, options)
    },
    getAppearanceInfo(appearanceCode) {

        let hideFields = [model_appearance.ENTITY_NAME]
        let appearanceFields = []
        Object.values(model_appearance).forEach(field => {
            if (!hideFields.includes(field)) {
                appearanceFields.push(field)
            }
        })
        let filters = {

            [model_appearance.CODE]: appearanceCode
        }

        this.getAppearances(appearanceFields, filters).then(data => {
            this.appearanceInfo = data.results[0]
        })

        return service_explorers.find('app-center/appearances', appearanceFields, filters)
    },

    updateAppearance(appearanceCode, properties) {
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/appearances/' + appearanceCode, objectToFormData(properties, {indices: true})
        )
    },
    createAppearance(properties) {
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/appearances/', objectToFormData(properties, {indices: true})
        )
    },
    getCertificateIssuers(fields, filters, options) {
        return service_explorers.find('app-center/certificate-issuers', fields, filters, options)
    },

    createOrUpdateCertificateIssuer(id, properties) {
        let params = {}
        Object.keys(properties).forEach((key_prop) => {
            if (typeof properties[key_prop] == 'undefined') {
                properties[key_prop] = null
            }
            params[key_prop] = properties[key_prop]
        })
        let form_data_object = objectToFormData(params, {indices: true})
        let url = id == null ? 'app-center/certificate-issuers' : 'app-center/certificate-issuers/' + id
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + url,
            form_data_object
        )
    },
    getPostalEnvelopes(fields, filters, options) {
        return service_explorers.find('app-center/postal/envelopes', fields, filters, options)
    },

    deletePostalEnvelope(envelope_code) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/postal/envelopes/' + envelope_code
        )
    },
    createOrUpdatePostalEnvelope(envelope_code, properties) {
        let params = {}
        Object.keys(properties).forEach((key_prop) => {
            if (typeof properties[key_prop] == 'undefined') {
                properties[key_prop] = null
            }
            params[key_prop] = properties[key_prop]
        })
        let form_data_object = objectToFormData(params, {indices: true})
        let url = envelope_code == null ? 'app-center/postal/envelopes' : 'app-center/postal/envelopes/' + envelope_code
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + url,
            form_data_object
        )
    },
    findBillingUnits(fields, filters, options) {
        return service_explorers.find('app-center/billing-units', fields, filters, options)
    },
    findGlobalVariables(fields, filters, options) {
        return service_explorers.find('app-center/config/variables', fields, filters, options)
    },

    findNotifications(fields, filters, options) {
        return service_explorers.find('app-center/notifications/history', fields, filters, options)
    },

    saveGlobalVariableProperties(global_variable_id, properties) {
        let params = {}
        Object.keys(properties).forEach((key_prop) => {
            if (typeof properties[key_prop] == 'undefined') {
                properties[key_prop] = null
            }
            params[key_prop] = properties[key_prop]
        })
        let form_data_object = objectToFormData(params, {indices: true})
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/config/variables/' + global_variable_id,
            form_data_object
        )
    },
    findEmailProviders(fields, filters, options) {
        return service_explorers.find('app-center/email/providers', fields, filters, options)
    },

    saveBillingUnitProperties(billing_unit_id, properties) {
        let params = {}
        Object.keys(properties).forEach((key_prop) => {
            if (typeof properties[key_prop] == 'undefined') {
                properties[key_prop] = null
            }
            params[key_prop] = properties[key_prop]
        })
        let form_data_object = objectToFormData(params, {indices: true})
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/billing-units/' + billing_unit_id,
            form_data_object
        )
    },
    createBillingUnit(info) {
        const formData = objectToFormData(info)
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/billing-units', formData
        ).then((response) => {
            return response.data
        })
    },
    findCallbacks(fields, filters, options) {
        return service_explorers.find('app-center/callbacks', fields, filters, options)
    },


    findLogs(fields, filters, options) {
        return service_explorers.find('app-center/logs/app-center', fields, filters, options)
    },

    findTickers(fields, filters, options) {
        return service_explorers.find('app-center/ticker', fields, filters, options)
    },

    findSchedules(fields, filters, options) {
        return service_explorers.find('app-center/scheduled-tasks', fields, filters, options)
    },
    updateSchedule(task_name, properties) {

        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/scheduled-tasks/' + task_name, objectToFormData(properties, {indices: true})
        )
    },


    getCertificationEntities() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'certificationentities').then(
            (response) => {
                let data = []
                response.data.forEach(ce => {
                    data.push({name: ce, code: ce})
                })
                return data
            }
        )
    },
    getEmailEntities() {
        return Axios
            .get(process.env.VUE_APP_ENDPOINT + 'email/entities')
            .then((response) => {
                return response.data
            })
    },
    findCommstrackerDomains(fields, filters, options) {
        return service_explorers.find('app-center/commstracker-domains', fields, filters, options)
    },
    createOrUpdateCommstrackerDomain(domain, properties) {
        let params = {}
        Object.keys(properties).forEach((key_prop) => {
            if (typeof properties[key_prop] == 'undefined') {
                properties[key_prop] = null
            }
            params[key_prop] = properties[key_prop]
        })
        let form_data_object = objectToFormData(params, {indices: true})
        let url = domain == null ? 'app-center/commstracker-domains' : 'app-center/commstracker-domains/' + domain
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + url,
            form_data_object
        )
    },
    deleteCommstrackerDomain(domain) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/commstracker-domains/' + domain
        )
    },
    getConfigOverview() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/errors/resume')
    },
    findSoftRejects(fields, filters, options) {
        return service_explorers.find('app-center/errors/soft-rejects', fields, filters, options)
    },
    findHardRejects(fields, filters, options) {
        return service_explorers.find('app-center/errors/hard-rejects', fields, filters, options)
    },

    retrySoftReject(error) {
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/soft-rejects/' + error.evidence_id + '/retry'
        )
    },
    deleteSoftReject(error) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/soft-rejects/' + error.evidence_id
        )
    },
    findErrorsHandler(fields, filters, options) {
        return this.findAlerts('handler', fields, filters, options)
    },
    deleteErrorsHandler(error) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/handler/' + error._id.$oid
        )
    },

    findServiceNotificationsAlerts(fields, filters, options) {
        return service_explorers.find('app-center/errors/service', fields, filters, options)
    },

    deleteServiceAlert(error) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/service/' + error._id.$oid
        )
    },

    findApiAlerts(fields, filters, options) {
        return service_explorers.find('app-center/errors/api', fields, filters, options)
    },
    deleteApiAlert(error) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/api/' + error.key
        )
    },
    findRedisAlerts(fields, filters, options) {
        return this.findAlerts('redis', fields, filters, options)
    },
    deleteRedisAlerts(error) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/redis/' + error.key
        )
    },
    retryRedisAlerts(error) {
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/redis/' + error.key + '/retry'
        )
    },
    deleteHardRejects(error) {
        return Axios.delete(
            process.env.VUE_APP_ENDPOINT + 'app-center/errors/hard-rejects/' + error._id.$oid
        )
    },
    findAlerts(id, fields, filters, options) {
        return service_explorers.find('app-center/errors/' + id, fields, filters, options)
    },
    getHosts() {
        let fields = [
            model_host.ID,
            model_host.API,
            model_host.STATUS,
            model_host.HOSTNAME,
            model_host.CALLBACK,
            model_host.FUNCTIONALITY,
            model_host.LAST_CHECK,
            model_host.ORCHESTRATION,
            model_host.SERVICE,
            model_host.RUNNING_COMMIT,
            model_host.TELEMETRY,
            model_host.TICKER,
            model_host.WORKER_SPAWNER

        ]
        return service_explorers.find('app-center/hosts', fields, null, null)

    },

    getHostStatus(hostname) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/hosts/' + hostname).then((response) => {

            return response.data
        })
    },
    deleteHost(hostname) {
        return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/hosts/' + hostname).then((response) => {
            return response.data
        })
    },
    getWorkersInfo() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/system/workers').then((response) => {

            return response.data
        })
    },
    getWorkersStatus() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/system/mode').then((response) => {

            return response.data
        })
    },
    setWorkersStatus(val) {
        let form_data_object = objectToFormData({
            systemMode: val
        }, {indices: true})
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/system/mode', form_data_object).then((response) => {

            return response.data
        })
    },
    clearRedisCache() {
        if (service_user.checkGroupPermission(P_MONITORING_HOSTS_STOP_WORKERS)) {
            return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/system/redis/flush-cache').then((response) => {
                return response.data
            })
        } else {
            router.push({name: 'forbidden', params: {code: 401}})
        }
    },
    getProviderBilling(provider, environment, year) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/billing/providers',
            {
                params: {
                    filters: {
                        provider: provider,
                        environment: environment,
                        year: year
                    },
                }
            }).then(
            (response) => {
                return response.data.extra
            }
        )
    },
    getCustomBilling() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/billing/custom').then(
            (response) => {
                let scripts = []
                response.data.extra.forEach((script) => {
                    scripts.push({'name': script})
                })
                return {

                    'results': scripts,
                    'count': scripts.length,
                    'time': 0,
                    'filters': {}

                }
            }
        )
    },
    launchCustomBilling(val) {
        let form_data_object = objectToFormData({
            script: val
        }, {indices: true})
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/billing/custom', form_data_object).then((response) => {
            return response.data
        })
    },

    findBatchUploads(fields, filters, options) {
        return service_explorers.find('app-center/batch-upload', fields, filters, options)
    },
    getDefaultProviders() {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/providers/default')
    },
    setDefaultProvider(type, value) {
        let form_data_object = objectToFormData({
            provider_class: type,
            provider_name: value
        }, {indices: true})
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/providers/default', form_data_object)
    }
}

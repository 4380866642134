<template>
	<v-col class="pa-0">
		<loading-container
			v-if="loggingOut"
			message="Cerrando sesión, por favor espera..."
		/>
		<div v-if="visible">
			<button
				v-shortkey="$shortKeys.search_bar.command"
				class="d-none"
				@shortkey="focusSearchBar"
			>
			</button>
			<button
				v-shortkey="$shortKeys.show_shortkeys_helpers.command"
				class="d-none"
				icon
				@shortkey="openShortKeyDialog"
			>
			</button>
			<ShortKeysDialog
				v-if="showShortKeyDialog"
				@closeDialog="showShortKeyDialog = false"
			/>
			<v-row
				v-if="$vuetify.breakpoint.mdAndUp"
				:class="padding ? 'cb-toolbar-padding':'cb-border-bottom'"
				class="cb-toolbar justify-lg-space-between"
			>

				<v-toolbar-title
					class="my-auto pl-4 font-weight-medium bg_dark--text"
				>
					<v-icon
						class="mr-2"
						color="bg_dark"
					>{{activeRoute.meta.icon}}
					</v-icon>
					<span
						class="cb-font-size-1"
						style="vertical-align: inherit;"
					>{{activeRoute.meta.title}}</span>
				</v-toolbar-title>
				<slot name="title"></slot>
				<div>
					<v-row>
						<GlobalSearchBar/>
						<div
							v-if="service_user.checkGroupPermission(P_MONITORING_ALERTS)"
							class="mx-4 my-auto"
						>
							<AlertMenu/>
						</div>
						<v-menu
							v-model="showUserActions"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									:color="$env.color"
									class="my-auto ml-0 cb-user-icon"
									icon
									v-bind="attrs"
									v-on="on"
								>

									<v-icon
										style="font-size: 40px"
										v-on="on"
									>account_circle
									</v-icon>
								</v-btn>
							</template>
							<v-expand-transition>
								<v-row
									v-if="showUserActions"
									class="ma-0 cb-sidebar-platform-list"
									dense
								>
									<v-col class="pa-0">
										<v-list>
											<v-list-item>
												<v-list-item-avatar>
													<v-icon
														:color="$env.color"
														style="font-size: 40px"
													>account_circle
													</v-icon>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title class="cb-font-size-3">
														{{authentication.name}}
													</v-list-item-title>
													<v-list-item-subtitle class="cb-font-size-4">{{
															authentication.group.name
														}}
													</v-list-item-subtitle>
												</v-list-item-content>

												<v-list-item-action>
													<v-btn
														color="error"
														icon
														@click="userAction('logout')"
													>
														<v-icon
															class="my-auto"
															title="Cerrar sesión"
														>logout
														</v-icon>

													</v-btn>
												</v-list-item-action>
											</v-list-item>
										</v-list>
										<v-divider class="mb-2"></v-divider>
										<v-list
											class="pa-0 pb-4"
											dense
										>
											<v-list-item
												class="py-1 px-4"
												@click="userAction('account')"
											>
												<v-list-item-icon class="ma-0 mr-2">
													<v-icon
														class="my-auto"
														color="primary"
													>person
													</v-icon>
												</v-list-item-icon>
												<v-list-item-title class="">Mi cuenta</v-list-item-title>
											</v-list-item>
											<v-list-item
												class="py-1 px-4"
												@click="openShortKeyDialog"
											>
												<v-list-item-icon class="ma-0 mr-2">
													<v-icon
														class="my-auto"
														color="primary"
													>help_outline
													</v-icon>
												</v-list-item-icon>
												<v-list-item-title class="">Ayuda</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-col>
								</v-row>
							</v-expand-transition>
						</v-menu>
					</v-row>
				</div>
			</v-row>
			<v-app-bar v-if="$vuetify.breakpoint.smAndDown">
				<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
				<v-toolbar-title
					class="title my-auto"
					v-html="activeRoute.meta.title"
				/>
			</v-app-bar>
			<v-navigation-drawer
				v-model="drawer"
				absolute
				temporary
			>
				<Sidebar
					v-if="drawer"
					:from-drawer="true"
				/>
			</v-navigation-drawer>
		</div>
	</v-col>
</template>

<script>
import vuex_global from '@/store/modules/sections/vuex_global'
import vuex_authentication from '@/store/modules/vuex_authentication'
import helpers from '@/plugins/helpers'
import {mapGetters} from 'vuex'
import ShortKeysDialog from '@/components/global_app/ShortKeysDialog'
import service_user from '@/services/service_user'
import {P_INSTANCES, P_MONITORING_ALERTS} from '@/constants/group_permissions'
import Sidebar from '@/components/global_app/Sidebar'
import AlertMenu from '@/components/global_app/Header/AlertMenu'
import LoadingContainer from '@/components/repository/LoadingContainer'
import GlobalSearchBar from '@/components/global_app/Header/GlobalSearchBar'

export default {
	name: 'HeaderApp',
	props: {custom: Boolean, padding: Boolean, visible: Boolean},
	components: {GlobalSearchBar, LoadingContainer, AlertMenu, Sidebar, ShortKeysDialog},
	data() {
		return {
			service_user,
			P_INSTANCES,
			P_MONITORING_ALERTS,
			drawer: false,
			loggingOut: false,
			helpers,
			timer: null,
			health: null,
			showUserActions: false,
			showShortKeyDialog: false,
			sessionMsRemaining: 0,
			interval: null
		}
	},
	computed: {
		...mapGetters({
			authentication: `${[vuex_authentication.constants.NAMESPACE]}/${[vuex_authentication.constants.G_LOGIN_DATA]}`,
		}),
		activeProfile() {
			return this.$store.state[vuex_global.constants.NAMESPACE][
				vuex_global.constants.S_ACTIVE_PROFILE
				]
		},
		activeRoute: {
			get() {
				return this.$route
			},
			set(val) {
				this.$store.dispatch(
					vuex_global.constants.NAMESPACE +
					'/' +
					vuex_global.constants.A_SET_ACTIVE_ROUTE,
					val
				)
			},
		},

		sessionRemainingPerc() {
			// return (this.sessionMsRemaining * 100 / this.timeoutStart.step) > 0 ? this.sessionMsRemaining * 100 / this.timeoutStart.step : 0
			return 100
		}
	},
	methods: {
		focusSearchBar() {
			this.$refs.searchBar.focus()
		},
		userAction(action) {
			if (action == 'logout') {
				this.loggingOut = true
				service_user.logout().then(() => {
					this.$store.dispatch(vuex_authentication.constants.NAMESPACE + '/' + vuex_authentication.constants.A_SET_LOGOUT)
				})
			} else if (action == 'account') {
				helpers.redirect('account')
			}
			this.showUserActions = false
		},
		openShortKeyDialog() {
			this.showShortKeyDialog = !this.showShortKeyDialog
		},
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/resources/variables";
@import "../../assets/styles/resources/colors";

.cb-border-bottom {
	border-bottom: 1px solid $color_app_contrast_medium;
}

.cb-header-route-link {
	font-size: $font-size-2 !important;
}

.cb-header-route-link a {
	text-decoration: none;
}

.cb-toolbar {
	height: 50px;
	min-height: 50px;
	background-color: $color_app_clear;
	//position: sticky;
	top: 0;
	z-index: 5;
	padding: 0px 20px 0px 10px;
	color: $color_app_bg_dark;
}


.cb-toolbar i {
	font-size: 25px !important;
}

.cb-toolbar .row {
	height: 100%;
}


.cb-toolbar .v-toolbar__content {
	padding: 0;
}

.cb-toolbar .v-list-item {
	padding: 0;
}

.cb-toolbar .cb-alert-chip {
	padding: 14px 8px !important;
	border-radius: 2px !important;

}

.cb-toolbar .cb-alert-chip .v-avatar {
	width: auto !important;
	padding: 0px 5px !important;
}

.cb-user-icon ::after {
	border-radius: 5px !important;
}
</style>

<template>
  <v-col
    v-if="options"
    class="pa-0 cb-table-container"
  >
    <v-data-table
      :headers="headers"
      :items="helpers.nullOrEmpty(data) ? [] : data.results"
      :items-per-page.sync="options.rpp"
      :loading="loading"
      :loading-text="'Cargando ' + model + '... Por favor espera'"
      :no-data-text="'No se han encontrado ' + model + ', actualiza los filtros'"
      :page.sync="options.page"
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      dense
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <slot :item="item"/>
        <StandardTableRow
          v-if="!customRow"
          :fields="headers"
          :item="item"
          @actionLaunched="actionLaunched"
        />
      </template>

    </v-data-table>
    <DataTableFooter
      :items-per-page="options.rpp"
      :model="model"
      :page="options.page"
      :page-count="helpers.nullOrEmpty(data) ? 1 : Math.ceil( data.count / options.rpp )"
      :result-time="helpers.nullOrEmpty(data) ? 0 : data.time"
      :total-items="helpers.nullOrEmpty(data) ? 0 : data.count"
      @[$events.DATA_TABLE_REFRESH]="refresh"
      @[$events.DATA_TABLE_CHANGE_PAGE]="changeDataTablePage"
    />
  </v-col>
</template>

<script>
import helpers_resolution from '@/plugins/helpers_resolution'
import DataTableFooter from '@/components/repository/tables/dataTable/DataTableFooter'
import helpers from '@/plugins/helpers'
import StandardTableRow from '@/components/repository/tables/dataTable/StandardTableRow.vue'

export default {
  name: 'DataTable',
  components: { StandardTableRow, DataTableFooter },
  props: {
    loading: Boolean,
    model: String,
    customRow: Boolean,
    headers: Array,
    dataTableOptions: Object,
    data: Object
  },
  data() {
    return {
      helpers,
      options: this.dataTableOptions != null ? this.dataTableOptions : {
        rpp: helpers_resolution.getTableRowsPerPage(),
        sortDesc: [false],
        sortBy: this.headers[0].value,
        page: 1,
      }
    }
  },
  watch: {
    options: {
      async handler() {
        this.refresh()
      },
      deep: true,
    }
  },
  methods: {
    refresh() {
      this.$emit(this.$events.DATA_TABLE_REFRESH)
    },
    changeDataTablePage(page) {
      this.options.page = page
    },
    actionLaunched(data) {
      this.$emit(data.action, data.item)
      this.$emit(this.$events.DATA_TABLE_ACTION_CLICKED, { action: data.action, info: data.item })
    },
    changeItemsPerPage(items) {
      this.options.rpp = items
    },
  },
}
</script>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.list-move {
  transition: transform 0.5s;
}

.item-row {
  display: table-row;
}
</style>